.simon-app .container {
  width: 100%;
}

.no-padding {
  padding: 0;
}

.nav p {
  margin: 0;
}

.game-button {
  height: 12rem;
  width: 100%;
  opacity: 0.5;
  cursor: pointer;
}

.active-button {
  opacity: 1;
}

.game-button-0 {
  background-color: cornflowerblue;
}

.game-button-1 {
  background-color: yellow;
}

.game-button-2 {
  background-color: red;
}

.game-button-3 {
  background-color: chartreuse;
}
