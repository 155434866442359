.name {
  color: orange;
}

.profile-image {
  height: 20rem;
}

.header-buttons {
  padding-top: 1rem;
}

.motto {
  margin: 0;
}
