.languages {
  text-align: center;
}

.languages span {
  display: block;
  padding: 1rem 0;
  text-align: center;
  font-size: 1.8rem;
  cursor: pointer;
}
