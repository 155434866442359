.calculator-app .btn {
	font-size: 1.8rem;
}

.btn:focus{
  outline: none !important;
}

.calculator-app .btn:hover, .calculator-app .btn:focus, .calculator-app .btn:active, .calculator-app .btn.active {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
}

.calculator-app .btn:active {
	background-color: none;
	-webkit-box-shadow: none;
    box-shadow: none;
}

.calculator-buttons {
	padding: 0;
}

.calculator-display {
  padding: 0 3rem;
	color: #333
}

@media (max-width: 480px) {
  .calculator-display {
    padding: 0 1.5rem;
  }
	.calculator-column {
		padding: 0 0.5rem;
	}
	.btn-block+.btn-block {
    margin-top: 1.5rem;
	}
}
