body {
  margin: 0;
  padding: 0;
  background-color: #333;
  font-family: sans-serif;
  font-weight: 300
}

h1, h2, h3, h4 {
  text-align: center;
}

h1 {
  color: orange;
  font-size: 6.8rem;
}

h2 {
  font-size: 4.0rem;
  margin-top: 0;
  margin-bottom: 2.5rem;
}

h3, h4 {
  font-size: 2.4rem;
}

h1, h2 {
  font-weight: 700;
}

h4, p, .btn {
  font-weight: 300;
}

p {
  font-size: 1.8rem;
}

.metadata {
  font-weight: 400;
  font-size: 1.5rem;
}

.row {
  margin: 0;
}

.section {
  padding: 4.5rem 0;
}

.dark-section {
  background-color: #333;
  color: #eee;
}

.neutral-section {
  background-color: #eee;
  color: #333;
}

.light-section {
  background-color: #fff;
  color: #333;
}

.btn-lg {
  margin: 1.5rem 0
}

.btn-toolbar .btn {
  float: none;
}

.btn {
  transition: all .2s ease-in-out;
}

.btn:hover, .btn:focus, .btn:active, .btn.active, .open > .dropdown-toggle.btn {
  transform: scale(1.1);
}

.btn-toolbar>.btn, .btn-toolbar>.btn-group, .btn-toolbar>.input-group {
    margin-left: 1rem;
}
